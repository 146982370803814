.faqs {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 8rem 0;

    &__body {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5rem;

        padding: 5rem;

        @include respond(phone-large) {
            padding: 2rem;
        }

        &__section {
            width: 100%;
        }

        &__head {
            display: flex;
            gap: 1rem;

            justify-content: flex-start;
            align-items: center;
            margin-bottom: 2rem;

            svg {
                width: 2.5rem;
                height: 2.5rem;
                color: $color-primary;
            }

            h3 {
                font-size: 1.8rem;
            }
        }
    }

    &__item {
        border: 1px solid $color-grey-dark;
        padding: 2rem;
        background-color: $color-grey-light-1;
        width: 100%;

        &__head {
            
            padding: 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            @include respond(phone-large) {
                justify-content: center;
                gap: 1rem;
            }
            

            h4 {
                font-size: 1.7rem;

                @include respond(phone-large) {
                    flex: 1;
                }
            }

            svg {
                width: 2rem;
                height: 2rem;
            }
        }

        &__body {
            display: none;
            width: 0;
        }
    }
}