.aboutus {
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 8rem 0;
    font-size: 1.6rem;

    &__body {
        padding: 5rem;

        width: 60%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 3rem;

        
        @include respond(tab-land) {
            width: 70%;
        }

        @include respond(phone-large) {
            width: 85%;
            padding: 2rem;
        }

        @include respond(phone-medium) {
            width: 90%;
            padding: 2rem;
        }

        p {
            &::first-letter {
                font-size: 3rem;
                color: $color-primary;
            }
        }
    }
}