.footer {
    padding: 6rem 2rem;
    background-color: rgba(blue, 0.1);

    &__head {

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;

        @include respond(tab-land) {
            flex-direction: column;
            
        }

        img {
            width: 20rem;
            height: auto;
        }

        &__contact {

            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            @include respond(tab-land) {
                margin-top: 3rem;
            }

            

            &__item {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 1rem;

                @include respond(tab-land) {
                    flex-direction: row-reverse;
                    justify-content: center;
                }

                @include respond(phone-large) {
                    flex-direction: column-reverse;
                }

                &__iconbox {
                    padding: 1rem;
                    background-color: green;
                    border-radius: 48%;

                    svg {
                        width: 2rem;
                        height: 2rem;
                        color: $color-white;
                    }
                }

                p {
                    font-weight: bold;
                }
            }
        }

    }

    &__body {

        display: flex;
        margin-top: 3rem;

        @include respond(tab-land) {
            flex-direction: column;
            gap: 5rem;
        }

        &__item {
            flex: 1;

            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            h4 {
                padding-bottom: 1rem;
                border-bottom: 1px solid $color-black;
            }

            ul {
                list-style: none;
                margin-top: 1.5rem;

                li {
                    text-align: center;
                }
            }
        }

    }
}