.locations {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__body {
        padding: 5rem;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include respond(phone-large) {
            padding: 3rem;
        }
        


        p {
            margin: 3rem 0;
            width: 80%;
            text-align: center;
        }

        &__list {

            display: flex;
            gap: 3rem;
            width: 100%;

            @include respond(tab-land) {
                flex-direction: column;
            }

            

            ul {
                width: 100%;

                li {
                    width: 90%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 1rem;
                    // border-top: 1px solid $color-primary;
                    border-bottom: 1.5px solid $color-primary;
                    cursor: pointer;
                    transition: all .2s;


                    &:hover {
                        background-color: rgba($color-primary, 0.5);
                    }

                    span {
                        font-size: 1.6rem;
                        // font-weight: 600;
                    }

                    svg {
                        width: 3rem;
                        height: 3rem;
                        color: $color-primary;
                    }
                }
            }

            &__head {
                font-weight: bold;
                font-size: 1.8rem;

                
            }

        }



    }
}