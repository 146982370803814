.promo {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    margin: 8rem 0;


    &__container {
        width: 80%;
        padding: 5rem;
        border: 1px solid red;
        background-color: rgba(red, 0.4);

        display: flex;
        align-items: center;
        justify-content: space-between;

        @include respond(tab-land) {
            flex-direction: column;
            gap: 3rem;
        }

        @include respond(phone-large) {
            padding: 3rem;
            width: 90%;
        }
    }

    &__left {
        flex: 1;

        @include respond(tab-land) {
            text-align: center;
        }

        &__main {
            font-size: 2rem;
            font-weight: 600;

        }

        &__sub {
            font-size: 1.5rem;
            margin-top: 1rem;
        }
    }

    &__right {
        flex: 1;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        &__top {
            display: flex;

            @include respond(tab-land) {
                flex-direction: column;
                gap: 1rem;
            }

            input {

                font-size: 1.6rem;
                padding: 1rem;

            }

            button {
                background-color: green;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1rem;
                border-radius: 0;
            }

        }

        &__bottom {
            margin-top: 1rem;
            font-size: 1.6rem;
        }

    }
}