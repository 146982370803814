.landing {
    width: 100%;
    height: 45rem;
    background-image: linear-gradient(to right, rgba($color-black, 0.7), rgba($color-black, 0.8)), url(../img/landing.jpg);
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;


    &__main {
        color: white;
        font-size: 4rem;
        text-align: center;
        animation: moveInRight 1s ease-out;

        @include respond(phone-large) {
            font-size: 3rem;
        }

        span {
            color: $color-primary;
        }
    }

    &__sub {
        color: white;
        font-size: 3.5rem;
        text-align: center;
        animation: moveInLeft 1s ease-out;

        @include respond(phone-large) {
            font-size: 2.5rem;
        }
    }
}