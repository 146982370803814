.services {

    
    margin: 8rem 0;
    font-size: 1.6rem;

    

    &__container {
        display: flex;
        gap: 8rem;
        margin-top: 5rem;


        @include respond(tab-land) {
            flex-direction: column;
            gap: 10rem;
            padding: 0 8rem;
        }

        @include respond(phone-large) {
            gap: 7rem;
            padding: 0 3rem;
        }

        @include respond(phone-medium) {
            gap: 5rem;
            padding: 0 1.5rem;
        }
    }

    &__item {

        flex: 1
    }

}