.reviews {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 8rem 0;

    &__head {

        margin: 3rem 5rem;
        padding: 1rem;
        background-color: lightblue;
        width: 85%;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @include respond(phone-large) {
            width: 90%;
            
        }

        &__google {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            padding: 1rem;
            border-bottom: 2px solid $color-grey-light-2;

            svg {
                width: 2rem;
                height: 2rem;
            }
        }

        &__rating {
            padding: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;

            p {
                font-size: 1.8rem;
                font-weight: bold;
            }

            span {
                font-size: 1rem;
            }

            &__stars {

                svg {
                    color: goldenrod;
                    width: 2rem;
                    height: 2rem;
                }

            }

            

        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5rem;

        padding: 5rem;

        @include respond(phone-large) {
            padding: 3rem;
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            gap: 3rem;
            justify-content: center;
            align-items: flex-start;
        }
    }

    &__btn {
        background-color: $color-primary;
    }

    &__item {
        width: 25rem;
        margin-bottom: 2rem;

        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 2rem;
        background-color: $color-grey-light-2;
        border-radius: 1rem;

        &__head {

            h5 {
                color: $color-primary;
            }

            p {
                margin-top: 5px;
            }
        }

        &__content {

            &__stars {

                svg {
                    color: goldenrod;
                }

            }

            p {

            }

        }
    }
}