.header {

    display: flex;
    justify-content: space-between;
    padding: 2rem;


    img {
        width: 20rem;
        height: auto;

        @include respond(phone-large) {
            width: 15rem;
        }

        @include respond(phone-medium) {
            width: 12rem;
        }

        @include respond(phone-small) {
            width: 10rem;
        }
    }

    &__signin {

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 7px;
        cursor: pointer;

        @include respond(phone-large) {
            flex-direction: column;
        }

        svg {
            width: 3rem;
            height: 3rem;
            color: $color-primary;
        }

        span {
            font-size: 1.4rem;
            font-weight: bold;
        }
    }
}