.login {

    // min-height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 6rem 0;

    background-color: rgba(lightblue, 0.3);
    margin-bottom: 8rem;

    
    form {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include respond(phone-large) {
            width: 100%;
        }
    }


    h3 {
        font-size: 4rem;
        margin-bottom: 2rem;
    }

    &__logo {
        margin-bottom: 2rem;

        img {
            width: 20rem;
            height: auto;
        }
    }

    &__form {

        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin-bottom: 2rem;

        @include respond(phone-large) {
            width: 92%;

            justify-content: center;
            align-items: center;
        }

        @include respond(phone-medium) {
          width: 94%;  
        }

        @include respond(phone-small) {
            width: 96%;
        }

        &__inputbox {
            margin-bottom: 2rem;

            @include respond(phone-large) {
                width: 100%;
                
            }

            label {
                font-weight: bold;
                margin-bottom: 1rem;
            }
        }

        &__input {

            padding: 1rem 1.5rem;
            width: 40rem;
            font-size: 1.4rem;


            @include respond(phone-large) {
                width: 95%;
            }

            &__wrap {
                display: flex;
                margin-top: 1rem;

            }

            &__icon {
                width: 2rem;
                height: 2rem;


                &__box {

                    padding: 1rem;
                    border: 2px solid $color-black;


                }
            }

            &__label {


                p {
                    span {}
                }
            }

        }

        &__checkbox {

            &__wrap {
                display: flex;
                gap: 1rem;

                label {

                    span {
                        color: green;
                        cursor: pointer;
                    }
                }
            }

            width: 1.5rem;
            height: 1.5rem;

        }
    }

    &__actionbtns {
        width: 43rem;

        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;

        @include respond(phone-large) {
            width: 90%;
        }

        @include respond(phone-small) {
            flex-direction: column;
            gap: 2rem;
        }

        &__rmbme {
            display: flex;
            gap: 1rem;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            input {
                width: 1.5rem;
                height: 1.5rem;
            }

            label {}
        }

        &__fgt {
            cursor: pointer;
        }

    }

    &__btnsection {
        margin-bottom: 2rem;
    }

    &__btn {
        background-color: $color-primary;
        width: 30rem;
    }

    &__create {
        color: $color-tertiary-dark;
        cursor: pointer;
        font-weight: bold;
        text-decoration: underline;
    }
}