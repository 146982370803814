.benefits {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 8rem 0;
    font-size: 1.6rem;

    &__body {

        padding: 5rem;

        display: flex;
        justify-content: center;
        align-items: center;

        gap: 4rem;

        @include respond(tab-land) {
            flex-direction: column;
        }

        @include respond(phone-large) {
            padding: 3rem;
        }

        &__img {
            flex: 1;

            img {
                width: 100%;
                height: auto;
            }
        }

        &__text {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            p {
                &::first-letter {
                    font-size: 2rem;
                    color: $color-primary;
                    font-weight: bold;
                }

                ul {
                    list-style: none;

                    display: flex;
                    flex-wrap: wrap;

                    @include respond(phone-large) {
                        flex-direction: column;
                    }


                    li {
                        width: 50%;

                        

                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        margin-bottom: 1rem;
                        gap: 5px;

                        @include respond(phone-large) {
                            width: 90%;
                            margin-bottom: 2rem;
                        }

                        span {
                            font-weight: bold;
                        }

                        svg {
                            color: white;
                        }

                    }
                }
            }

            &__iconbox {
                padding: 1rem;
                background-color: $color-primary;
                border-radius: 50%;

                svg {
                    width: 2rem;
                    height: 2rem;
                }
            }
        }
    }
}