.operation {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 8rem 0;
    font-size: 1.6rem;

    

    &__body {
        padding: 5rem;

        display: flex;
        gap: 4rem;

        @include respond(tab-land) {
            flex-direction: column;
        }

        @include respond(phone-large) {
            padding: 3rem;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;

        img {
            width: 10rem;
            height: 10rem;
        }

        h4 {

        }

        p {
            text-align: center;
        }
    }
}