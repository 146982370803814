.whyus {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 8rem 0;

    &__body {
        display: flex;
        gap: 5rem;

        padding: 5rem;

        @include respond(tab-land) {
            flex-direction: column;
        }

        @include respond(phone-large) {
            padding: 3rem;
        }
    }

    &__item {

        display: flex;
        gap: 1rem;

        img {
            width: 8rem;
            height: 8rem;
        }

        &__details {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            h4 {
                color: $color-primary;
            }

            p {
                font-size: 1.2rem;
            }
        }
    }
}